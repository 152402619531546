import m, * as bacta from "bacta"
import {
	RadioGroupAttrs,
	RadioButtonAttrs,
	RadioGroup,
	RadioButton,
} from "../sl/radio-group.js"

type BaseButtonAttrs = {
	value: string | number
	sl?: Partial<RadioButtonAttrs>
}

type BaseGroupAttrs =
	| {
			query: bacta.Store<string>
			sl?: Partial<RadioGroupAttrs>
			disabled?: boolean
	  }
	| {
			query: bacta.Store<number> | bacta.Store<null>
			sl?: Partial<RadioGroupAttrs>
			disabled?: boolean
	  }

export type MainRadioButtonAttrs = BaseButtonAttrs

export type MainRadioGroupAttrs = BaseGroupAttrs

export const HarthRadioGroup: bacta.ClosureComponent<
	MainRadioGroupAttrs
> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			RadioGroup,
			{
				...sl,
				value: attrs.query.get(),
				["onsl-change"](e: any) {
					const value = (e.target as BaseButtonAttrs)?.value
					;(attrs.query.set as any)(value)
				},
				disabled: attrs.disabled,
			},
			children
		)
	},
})

export const HarthRadioButton: bacta.ClosureComponent<
	MainRadioButtonAttrs
> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			RadioButton,
			{
				...attrs,
				...sl,
			},
			children
		)
	},
})
