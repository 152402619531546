import m, * as bacta from "bacta"
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/radio-group/radio-group.js"
import "@shoelace-style/shoelace/dist/components/radio-button/radio-button.js"
import css from "../../main.module.css"

export type RadioGroupAttrs = {
	label?: string
	name?: string
	value?: string | boolean | number | null
	helpText?: string

	id?: string
	"onsl-change"?(event: InputEvent): void
	"onsl-input"?(event: InputEvent): void
}

export type RadioButtonAttrs = {
	value: string | boolean | number
	size?: "small" | "medium" | "large"
	class?: string
	disabled?: boolean

	id?: string
}

export const RadioGroup: bacta.ClosureComponent<RadioGroupAttrs> = () => ({
	view: ({ attrs = {}, children = [] }) => {
		return m("sl-radio-group", attrs, children)
	},
})

export const RadioButton: bacta.ClosureComponent<RadioButtonAttrs> = () => ({
	view: ({ attrs = {}, children = [] }) =>
		m("sl-radio-button." + css[attrs.class || ""], attrs, children),
})
